import React, {Suspense} from "react"
import HeroBlock from "../Core/HeroBlock"
import Fallback from "../Core/Fallback"

const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"))

const PageContents = ({ blocks }) => {
  let pageBlocks = []
  let faqs = []
  const isSSR = typeof window === "undefined"

  blocks.forEach((block, index) => {
    const { __typename: type } = block
    if (type === "ContentfulHeroBlock") {
      pageBlocks.push(<HeroBlock {...block} key={index} />)
    }
    if (type === "ContentfulQuizCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><QuizCtaBlock {...block} plp/></Suspense>}</React.Fragment>)
    }
  })

  pageBlocks.splice(1, 0, <div id="gladly-help-center"></div>)

  return (
    <React.Fragment>
      {pageBlocks}
    </React.Fragment>
  )
}

export default PageContents
